export default {
  label1: {
    fontSize: 29,
    lineHeight: '110%'
  },
  label2: {
    fontSize: 27,
    lineHeight: '110%'
  },
  label3: {
    fontSize: 19,
    lineHeight: '110%'
  },
  label4: {
    fontSize: 15,
    lineHeight: '110%'
  },
  label5: {
    fontSize: 13,
    lineHeight: '110%'
  },
  label6: {
    fontSize: 11,
    lineHeight: '110%'
  }
}
