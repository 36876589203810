import Color from 'color'

// Texts
export const white = Color('#FFFFFF')
export const gray = Color('#000000').alpha(0.5)
export const black = Color('#000000')

// Errors
export const red = Color('#FA768D')
export const yellow = Color('#F6D57D')
export const green = Color('#60C789')

// Ui Light Theme
export const alabaster = Color('#F9FAFB')
export const blackhaze = Color('#F4F8F7')
export const tranquil = Color('#DFE7E8')
export const tiara = Color('#C4D1D5')
export const cadetGray = Color('#91A8AB')
export const blueBayoux = Color('#637A81')
export const casal = Color('#45575B')
export const nordic = Color('#213636')

// Ui Accents
export const mediumAquamarine = Color('#6CE1C5')
export const jungleGreen = Color('#34B193')
export const genoa = Color('#1D7868')

// Ui Dark Theme
export const oracle = Color('#385353')
export const firefly = Color('#2E4646')
export const mediumJungleGreen = Color('#203434')
export const aztec = Color('#101F1E')
export const woodSmoke = Color('#091212')

export default {
  uiColors: {
    uiDarks: {
      uiDark001: oracle.toString(),
      uiDark002: firefly.toString(),
      uiDark003: mediumJungleGreen.toString(),
      uiDark004: aztec.toString(),
      uiDark006: woodSmoke.toString()
    },
    uiTexts: {
      uiText001: white.toString(),
      uiText002: black.toString(),
      uiText003: gray.toString()
    },
    uiErrors: {
      uiError001: red.toString(),
      uiError002: yellow.toString(),
      uiError003: green.toString()
    },
    uiLights: {
      uiLight001: alabaster.toString(),
      uiLight002: blackhaze.toString(),
      uiLight003: tranquil.toString(),
      uiLight004: tiara.toString(),
      uiLight005: cadetGray.toString(),
      uiLight006: blueBayoux.toString(),
      uiLight007: casal.toString(),
      uiLight008: nordic.toString()
    },
    uiAccents: {
      uiAccent001: mediumAquamarine.toString(),
      uiAccent002: jungleGreen.toString(),
      uiAccent003: genoa.toString()
    }
  }
}
