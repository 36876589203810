import { Playground, Props } from 'docz';
import { IcoArrow, IcoChevron, IcoCheck, IcoClose, IcoHelp, IcoInfo, IcoSearch, IcoRefresh } from '@redoute/icons';
import * as React from 'react';
export default {
  Playground,
  Props,
  IcoArrow,
  IcoChevron,
  IcoCheck,
  IcoClose,
  IcoHelp,
  IcoInfo,
  IcoSearch,
  IcoRefresh,
  React
};