import React from 'react'
import { Global, css } from '@emotion/core'

const GlobalStyles = () => (
  <Global
    styles={css`
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: white;
      }
      ::-webkit-scrollbar-track {
        background-color: black;
      }

      body,
      html,
      #root {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        font-size: 13px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      body {
        background: white;
      }

      * {
        box-sizing: border-box;
      }
    `}
  />
)

export default GlobalStyles
