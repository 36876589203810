export default {
  paragraph1: {
    fontSize: 29,
    lineHeight: '110%'
  },
  paragraph2: {
    fontSize: 27,
    lineHeight: '110%'
  },
  paragraph3: {
    fontSize: 19,
    lineHeight: '110%'
  },
  paragraph4: {
    fontSize: 15,
    lineHeight: '110%'
  },
  paragraph5: {
    fontSize: 13,
    lineHeight: '110%'
  },
  paragraph6: {
    fontSize: 11,
    lineHeight: '110%'
  }
}
