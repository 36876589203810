export default {
  color: 'blue',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline'
  },
  ':visited': {
    color: 'purple'
  },
  ':active': {
    color: 'red'
  },
  ':focus': {
    outlineColor: '#4D90FE',
    outlineOffset: '-2px',
    outlineWidth: '5px'
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.3,
    ':hover': {
      opacity: 0.3,
      textDecoration: 'none'
    }
  }
}
