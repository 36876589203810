export default {
  border: '1px solid black',
  padding: '10px 20px',
  borderRadius: 2,
  textDecoration: 'none',
  background: 'lightgray',
  display: 'inline-flex',
  color: 'black',
  ':hover': {
    background: 'black',
    textDecoration: 'none',
    color: 'white'
  },
  ':active': {
    background: 'red'
  },
  ':focus': {
    outlineColor: '#4D90FE',
    outlineOffset: '-2px',
    outlineWidth: '5px',
    outlineStyle: 'auto'
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.5,
    '&:hover': {
      color: 'black',
      background: 'lightgray'
    }
  }
}
