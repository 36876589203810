export default {
  color: '#4D90FE',
  ':hover': {
    textDecoration: 'none',
    opacity: 0.5
  },
  ':active': {
  },
  ':focus': {
    outlineColor: '#4D90FE',
    outlineOffset: '-2px',
    outlineWidth: '5px',
    outlineStyle: 'auto'
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.5
  }
}
