import styled from '@emotion/styled'
import { isEmpty } from 'lodash'

const titleRules = (level) => ({ theme }) => {
  const title = (!isEmpty(theme) && theme.titles[`title${level}`]) || {}
  return {
    margin: 0,
    padding: 0,
    ...title
  }
}

export const Title1 = styled.h1(titleRules(1))
export const Title2 = styled.h2(titleRules(2))
export const Title3 = styled.h3(titleRules(3))
export const Title4 = styled.h4(titleRules(4))
export const Title5 = styled.h5(titleRules(5))
