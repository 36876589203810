export default {
  title1: {
    fontSize: 88,
    lineHeight: '110%'
  },
  title2: {
    fontSize: 55,
    lineHeight: '120%'
  },
  title3: {
    fontSize: 34,
    lineHeight: '130%'
  },
  title4: {
    fontSize: 21,
    lineHeight: '140%'
  },
  title5: {
    fontSize: 19,
    lineHeight: '140%'
  },
  title6: {
    fontSize: 15,
    lineHeight: '140%'
  }
}
