import React from 'react'
import redouteTheme from './theme.js'
import { ThemeProvider } from 'emotion-theming'
import RedouteGlobalStyles from './GlobalStyles.js'

const RedouteThemeProvider = props => (
  <ThemeProvider theme={redouteTheme} {...props} />
)

export default RedouteThemeProvider
export { redouteTheme, RedouteGlobalStyles }
