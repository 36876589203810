import { Playground, Props } from 'docz';
import colors from "../../../../../src/packages/Theme/lib/parts/colors";
import { ColorViewer, ColorSection, ColorWrapper } from "../../../../../src/packages/Theme/lib/viewers/color.js";
import * as React from 'react';
export default {
  Playground,
  Props,
  colors,
  ColorViewer,
  ColorSection,
  ColorWrapper,
  React
};