import paragraphs from './parts/paragraphs'
import titles from './parts/titles'
import labels from './parts/labels'
import link from './parts/link'
import button from './parts/button'
import sliderRange from './parts/slider-range'
import toggleSwitch from './parts/toggle-switch'
import icon from './parts/icon'
import uiColors from './parts/colors'

export default {
  paragraphs,
  labels,
  titles,
  link,
  button,
  sliderRange,
  toggleSwitch,
  icon,
  uiColors
}
