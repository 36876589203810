export default {
  toggleInput: {
    width: 0,
    height: 0,
    display: 'none'
  },
  toggleButton: {
    content: '""',
    position: 'absolute',
    top: 2,
    left: 2,
    width: 18,
    height: 18,
    borderRadius: 20,
    backgroundColor: 'white',
    ':hover': {
      opacity: 0.7
    },
    ':checked': {
      left: 'calc(100% - 2px)',
      transform: 'translateX(-100%)'
    },
    ':disabled': {
      backgroundColor: 'gray',
      boxShadow: 'inset 0px 0px 0px 2px white',
      ':hover': {
        opacity: 1
      }
    }
  },
  toggleLabel: {
    display: 'flex',
    margin: '2px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: 40,
    height: 22,
    borderRadius: 100,
    position: 'relative',
    backgroundColor: 'gray',
    ':focus': {
      border: '2px solid #2AC58D'
    },
    ':checked': {
      backgroundColor: 'black'
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.3,
      backgroundColor: 'transparent',
      boxShadow: 'inset 0px 0px 0px 2px gray',
      ':hover': {
        border: 0
      }
    }
  },
  toggleState: {
    position: 'absolute',
    left: '120%',
    whiteSpace: 'nowrap',
    userSelect: 'none'
  }
}
