import { Playground, Props } from 'docz';
import { MemoryRouter as Router } from 'react-router-dom';
import Link from '@redoute/link';
import { Title1 } from '@redoute/titles';
import * as React from 'react';
export default {
  Playground,
  Props,
  Router,
  Link,
  Title1,
  React
};