import { useState } from 'react';
import { Playground, Props } from 'docz';
import SliderRange from '@redoute/slider-range';
import * as React from 'react';
export default {
  useState,
  Playground,
  Props,
  SliderRange,
  React
};