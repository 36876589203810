import { Playground, Props } from 'docz';
import { Paragraph1, Paragraph2, Paragraph3, Paragraph4, Paragraph5, Paragraph6 } from '@redoute/paragraphs';
import * as React from 'react';
export default {
  Playground,
  Props,
  Paragraph1,
  Paragraph2,
  Paragraph3,
  Paragraph4,
  Paragraph5,
  Paragraph6,
  React
};