import { Playground, Props } from 'docz';
import { Label1, Label2, Label3, Label4, Label5, Label6 } from '@redoute/labels';
import * as React from 'react';
export default {
  Playground,
  Props,
  Label1,
  Label2,
  Label3,
  Label4,
  Label5,
  Label6,
  React
};