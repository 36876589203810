import { Playground, Props } from 'docz';
import { useState } from 'react';
import ToggleSwitch, { ToggleState } from '@redoute/toggle-switch';
import * as React from 'react';
export default {
  Playground,
  Props,
  useState,
  ToggleSwitch,
  ToggleState,
  React
};