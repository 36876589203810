import { Playground, Props } from 'docz';
import { Title1, Title2, Title3, Title4, Title5 } from "../../../../../src/packages/Titles/lib/index.js";
import * as React from 'react';
export default {
  Playground,
  Props,
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  React
};