// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---readme-mdx": () => import("./../../../../README.mdx" /* webpackChunkName: "component---readme-mdx" */),
  "component---src-packages-button-readme-mdx": () => import("./../../../../src/packages/Button/README.mdx" /* webpackChunkName: "component---src-packages-button-readme-mdx" */),
  "component---src-packages-deploy-readme-mdx": () => import("./../../../../src/packages/deploy/README.mdx" /* webpackChunkName: "component---src-packages-deploy-readme-mdx" */),
  "component---src-packages-icons-readme-mdx": () => import("./../../../../src/packages/Icons/README.mdx" /* webpackChunkName: "component---src-packages-icons-readme-mdx" */),
  "component---src-packages-labels-readme-mdx": () => import("./../../../../src/packages/Labels/README.mdx" /* webpackChunkName: "component---src-packages-labels-readme-mdx" */),
  "component---src-packages-link-readme-mdx": () => import("./../../../../src/packages/Link/README.mdx" /* webpackChunkName: "component---src-packages-link-readme-mdx" */),
  "component---src-packages-paragraphs-readme-mdx": () => import("./../../../../src/packages/Paragraphs/README.mdx" /* webpackChunkName: "component---src-packages-paragraphs-readme-mdx" */),
  "component---src-packages-slider-range-readme-mdx": () => import("./../../../../src/packages/SliderRange/README.mdx" /* webpackChunkName: "component---src-packages-slider-range-readme-mdx" */),
  "component---src-packages-theme-colors-mdx": () => import("./../../../../src/packages/Theme/colors.mdx" /* webpackChunkName: "component---src-packages-theme-colors-mdx" */),
  "component---src-packages-theme-readme-mdx": () => import("./../../../../src/packages/Theme/README.mdx" /* webpackChunkName: "component---src-packages-theme-readme-mdx" */),
  "component---src-packages-titles-readme-mdx": () => import("./../../../../src/packages/Titles/README.mdx" /* webpackChunkName: "component---src-packages-titles-readme-mdx" */),
  "component---src-packages-toggle-switch-readme-mdx": () => import("./../../../../src/packages/ToggleSwitch/README.mdx" /* webpackChunkName: "component---src-packages-toggle-switch-readme-mdx" */),
  "component---src-packages-with-mouse-states-readme-mdx": () => import("./../../../../src/packages/withMouseStates/README.mdx" /* webpackChunkName: "component---src-packages-with-mouse-states-readme-mdx" */),
  "component---src-packages-wording-readme-mdx": () => import("./../../../../src/packages/wording/README.mdx" /* webpackChunkName: "component---src-packages-wording-readme-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

