import React from 'react'
import styled from '@emotion/styled'

const Color = styled.div`
    width: 200px;
    padding: 1rem 1rem;
    background-color: ${(props) => props.color};
`

const ColorsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
`

const ColorViewer = ({ label, color }) => {
  return (
    <Color color={color}>
      {label}
    </Color>
  )
}

const ColorSection = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  )
}

const ColorWrapper = ({ children }) => {
  return (
    <ColorsWrapper>
      {children}
    </ColorsWrapper>
  )
}

export { ColorViewer, ColorWrapper, ColorSection }
